@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;800&display=swap');

/* Preload Fonts (Critical) */
@font-face {
  font-family: 'Inter';
  src: url('https://fonts.gstatic.com/s/inter/v7/UcC9a6V8XklR0UeSmoTsm.woff2') format('woff2');
  font-display: swap;
}

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

/* Colors and Styles (Root Variables) */
:root {
  --PrimaryColor: #46797e;
  --HoverColor: #3ed6e0;
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: #9694AA;
  --bgColor: #f8f9fa;
  
  /* Font Sizes */
  --biggestFontSize: 2.5rem;
  --h1FontSize: 1.5rem;
  --h2FontSize: 1.25rem;
  --h3FontSize: 1rem;
  --normalFontSize: 0.938rem;
  --smallFontSize: 0.813rem;
  --smallestFontSize: 0.75rem;
}

/* Default Link Styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Default List Styles */
li {
  list-style: none;
}

/* Image Styles */
img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

/* Typography Enhancements */
h1, h2, h3, p {
  color: var(--blackColor);
  margin-bottom: 1rem;
  line-height: 1.5;
}

h1 {
  font-size: var(--h1FontSize);
  font-weight: 800;
}

h2 {
  font-size: var(--h2FontSize);
  font-weight: 600;
}

h3 {
  font-size: var(--h3FontSize);
  font-weight: 600;
}

p {
  font-size: var(--normalFontSize);
  color: var(--textColor);
}

/* Buttons */
button {
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: var(--normalFontSize);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--HoverColor);
}

/* Hero Section Optimization for LCP */
.hero-title {
  font-family: 'Inter', Arial, sans-serif;
  font-size: 2rem;
  font-weight: 800;
  color: #000;
  text-align: center;
  margin-bottom: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.hero-description {
  font-size: 1.25rem;
  color: #555;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

/* Form Inputs */
input, textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--textColor);
  border-radius: 0.5rem;
  font-size: var(--normalFontSize);
}

input:focus, textarea:focus {
  outline: none;
  border-color: var(--PrimaryColor);
}

/* Footer Styles */
.footer {
  background-color: var(--blackColor);
  color: var(--whiteColor);
  padding: 2rem;
  text-align: center;
}

.footer a {
  color: var(--HoverColor);
}

.footer a:hover {
  color: var(--PrimaryColor);
}
